import axios from 'axios';
import React, { useEffect, useState } from 'react';
import { Table, Row, Col } from 'reactstrap';

function StatsTables(props) {
  const [evalmetrics, setEvalmetrics] = useState([]);
    const fetchPortFolioData = async () => {
      try {
        const response = await axios.get(
          `${process.env.REACT_APP_BASE_URL}/portfolios/?skip=0&limit=10`
        );
        if (response.status === 200) {
          const findPortfolio =
            response?.data?.data?.length &&
            response.data.data.find((data) => data.id === props?.data?.id);
          fetchData(findPortfolio ? 'portfolio' : 'assets');
        }
      } catch (error) {
        console.error('Error fetching portfolio data:', error);
        return [];
      }
    };
    const fetchData = async (type) => {
      try {
        let response;
        if (type === 'assets') {
          response = await axios.get(
            `${process.env.REACT_APP_BASE_URL}/assets/${props?.data?.id}/evalmetrics?skip=0&limit=10`
          );
        } else {
          response = await axios.get(
            `${process.env.REACT_APP_BASE_URL}/portfolios/${props?.data?.id}/evalmetrics?skip=0&limit=10`
          );
        }
        if (response.status === 200) {
          setEvalmetrics(response?.data);
        }
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    };
    useEffect(() => {
      if (props?.data?.id) {
        fetchPortFolioData();
      }
    }, [props]);

  return (
    <Row className="p-3">
      {/* <Col> */}
      <Table striped bordered responsive className="stats-table">
        <thead>
          <tr>
            <th>Metric</th>
            <th>MQE Strategy</th>
            <th>HFRI 500</th>
          </tr>
        </thead>
        <tbody>
          {evalmetrics?.length ? (
            <>
              {evalmetrics.map((item) => (
                <tr>
                  <td>{item?.metric}</td>
                  <td>{item?.mqe}</td>
                  <td>{item?.hfri}</td>
                </tr>
              ))}
            </>
          ) : (
            <></>
          )}
        </tbody>
      </Table>
    </Row>
  );
}

export default StatsTables;
